
export default {
  install(Vue, options) {
    Vue.prototype.$rulesAlfaNum = function (v) {
      const pattern = /^[A-ZÑa-zñáéíóúÁÉÍÓÚ´' 0-9]+$/;
      if (typeof v !== "undefined" && v != null && v !== "") {
        if (!pattern.test(v)) return "Se deben ingresar letras o numeros";
      }
      if (typeof v !== "undefined" && v != null && v !== "") {
        return v.length <= 500 || "Max 500 caracteres";
      }
      return true;
    };

    

    Vue.prototype.$rulesAlfaNumMax50 = function (v) {
      const pattern = /^[A-ZÑa-zñáéíóúÁÉÍÓÚ´' 0-9]+$/;
      if (typeof v !== "undefined" && v != null && v !== "") {
        if (!pattern.test(v)) return "Se deben ingresar letras o numeros";
      }
      if (typeof v !== "undefined" && v != null && v !== "") {
        return v.length <= 50 || "Max 50 caracteres";
      }
      return true;
    };

    Vue.prototype.$rulesAlfaNumMax6 = function (v) {
      const pattern = /^[A-ZÑa-zñáéíóúÁÉÍÓÚ´' 0-9]+$/;
      if (typeof v !== "undefined" && v != null && v !== "") {
        if (!pattern.test(v)) return "Se deben ingresar letras o numeros";
      }
      if (typeof v !== "undefined" && v != null && v !== "") {
        return v.length <= 6 || "Max 6 caracteres";
      }
      return true;
    };

    Vue.prototype.$rulesRequerido = function (value) {
      //console.log(value);
      if (typeof value !== "undefined") {
        if (value !== null && value !== "") {
          return true;
        } else {
          return "Este campo es requerido.";
        }
      }
      return "Este campo es requerido.";
    };

    Vue.prototype.$rulesRequeridoOtro = function (value) {
      if (typeof value !== "undefined") {
        if (value !== null && value !== "") {
          return true;
        } else {
          return "Este campo es requerido.";
        }
      }
      return "Este campo es requerido.";
    };

    Vue.prototype.$rulesDni = function (v) {
      const pattern = /^\d{7,8}$/;
      if (!pattern.test(v))
        return "Debe ser numérico y tener al menos 7 digitos";

      return true;
    };

    Vue.prototype.$rulesDniMax9 = function (v) {
      const pattern = /^\d{7,9}$/;
      if (!pattern.test(v))
        return "Debe ser numérico y tener al menos 7 digitos";

      return true;
    };

    Vue.prototype.$rulesMail = function (value) {
      const pattern = /^$|.+@.+\..+/;
      if (!pattern.test(value)) {
        return "Este campo debe ser un e-mail válido.";
      }
      return true;
    };

    Vue.prototype.$rulesFloatRequerido = function (v) {
      if (!!v) {
        if (parseFloat(v) > 0) {
          return true;
        } else {
          return "Ingrese un valor mayor o igual a 1";
        }
      } else {
        return "Este campo es requerido.";
      }
    };

    Vue.prototype.$rulesNumeroCertificado = function (v) {
      const pattern = /\b[0-9]{2}-(\D)?[0-9]{8}\/(\D)?[0-9]{4}/;
      if (typeof v !== "undefined") {
        if (!pattern.test(v)) {
          return "Ingrese el numero de certificado con el formato: 99-99999999/9999";
        } else {
          return true;
        }
      } else {
        return true;
      }
    };

    Vue.prototype.$rulesEnterosPositivos = function (v) {
      const pattern = /^[1-9]\d*$/;

      if (typeof v != "undefined" && v != null) {
        if (!pattern.test(v) && v.length > 0) {
          return "Debe ser numérico";
        }
        return true;
      }
      return true;
    };

    Vue.prototype.$rulesEnterosPositivosOCero = function (v) {
      const pattern = /^[0-9]\d*$/;

      if (typeof v != "undefined" && v != null) {
        if (!pattern.test(v) && v.length > 0) {
          return "Debe ser numérico";
        }
        return true;
      }
      return true;
    };

    Vue.prototype.$rulesPorcentaje = function (v) {
      const pattern = /^[0-9]+$/;

      if (pattern.test(v)) {
        if (parseInt(v) > 0 && parseInt(v) <= 100) {
          return true;
        } else if (parseInt(v) == 0) {
          return "El porcentaje no puede ser igual a 0";
        } else {
          return "El porcentaje no puede ser mayor a 100";
        }
      }
      return "Ingreso inválido. Ingrese solo números";
    };

    Vue.prototype.$rulesNupcias = function (v) {
      const pattern = /^[0-9]+$/;

      if (pattern.test(v)) {
        if (parseInt(v) >= 0 && parseInt(v) < 100) {
          return true;
        } else if (parseInt(v) == 0) {
          return "El número de nupcias no puede ser menor a 0";
        } else {
          return "El número de nupcias no puede ser mayor a 99";
        }
      }
      return "Ingreso inválido. Ingrese solo números";
    };

    Vue.prototype.$rulesNumericoMax15 = function (v) {
      const pattern = /^\d*$/;
      if (!pattern.test(v)) return "Debe ser numérico";

      if (typeof v !== "undefined" && v.length > 15) {
        return "Max. 15 caracteres";
      }
      return true;
    };

    Vue.prototype.$rulesNumericoMax10 = function (v) {
      const pattern = /^\d*$/;
      if (!pattern.test(v)) return "Debe ser numérico";

      if (typeof v !== "undefined") {
        return v.length <= 10 || "Max. 10 caracteres";
      }
      return true;
    };

    Vue.prototype.$rulesNumericoMax6 = function (v) {
      const pattern = /^\d*$/;
      if (!pattern.test(v)) return "Debe ser numérico";

      if (typeof v !== "undefined") {
        return v.length <= 6 || "Max. 6 caracteres";
      }
      return true;
    };

    Vue.prototype.$rulesTelefono = function (v) {
      const pattern = /^\d*$/;
      if (!pattern.test(v)) return "Debe ser numérico";

      if (typeof v !== "undefined") {
        return v.length <= 20 || "Max. 20 caracteres";
      }
      return true;
    };

    Vue.prototype.$rulesNumericos = function (v) {
      const pattern = /^\d*$/;
      if (!pattern.test(v)) return "Debe ser numérico";

      /*if (typeof v !== "undefined") {
        return v.length <= 20 || "Max. 20 caracteres";
      }*/
      return true;
    };
    Vue.prototype.$rulesNumeroMinimo = function (v) {
      if (v < 10) return "Minimo 10 unidades";
      return true;
    };
    Vue.prototype.$rulesFormularios = function (v) {
      const pattern = /^\d*$/;
      if (!pattern.test(v)) return "Debe ser numérico";

      if (typeof v !== "undefined") {
        return v.length <= 8 || "Max. 8 caracteres";
      }
      return true;
    };

    Vue.prototype.$facturaLength = function (v) {
      if (typeof v !== "undefined") {
        return v.length === 12 || "El número de factura debe tener 12 dígitos";
      }
      return true;
    };

    Vue.prototype.$puntoVentaLength = function (v) {
      if (typeof v !== "undefined") {
        return v.length === 4 || "El punto de venta debe tener 4 dígitos";
      }
      return true;
    };

    Vue.prototype.$rulesChasis = function (v) {
      const pattern = /^[A-ZÑa-zñáéíóúÁÉÍÓÚ' 0-9]+$/;
      if (!pattern.test(v)) return "Debe ser  alfanumérico";

      if (typeof v !== "undefined") {
        return (
          v.toString().length >= 7 ||
          "Debe ingresar los últimos 7 números del chasis"
        );
      }
      return true;
    };

    Vue.prototype.$rulesCuit = function (v) {
      const pattern = /\b[0-9]{2}-(\D)?[0-9]{8}\-(\D)?[0-9]{1}/;
      if (typeof v !== "undefined") {
        if (!pattern.test(v)) {
          return "Ingrese el número de cuit con el formato: 99-99999999-9";
        } else {
          return true;
        }
      } else {
        return true;
      }
      
    };

    Vue.prototype.$rulesValidCuit = async function (cuit) {
            
      if (typeof cuit !== "undefined") {
        if (!/^\d{11}$/.test(cuit)) {
            return false;
        }

          // Coeficientes para validar el CUIT
          const coeficientes = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
          
          // Separar los primeros 10 dígitos y el dígito verificador
          const cuitSinVerificador = cuit.slice(0, 10);
          const digitoVerificador = parseInt(cuit[10], 10);
          
          // Calcular la suma ponderada
          let suma = 0;
          for (let i = 0; i < coeficientes.length; i++) {
              suma += parseInt(cuitSinVerificador[i], 10) * coeficientes[i];
          }
          
          // Calcular el dígito verificador esperado
          const resto = suma % 11;
          const digitoEsperado = resto === 0 ? 0 : 11 - resto;
    
    // Verificar si el dígito verificador coincide
        return digitoEsperado === digitoVerificador;

          
        
      } else {
        return true;
      }
      
    };

    Vue.prototype.$rulesImg = function (v) {
      if (!!v) {
        const pattern = /^$|^.*\.(jpg|jpeg|bmp|png)$/;
        if (!pattern.test(v.name))
          return "El archivo debe ser una imagen JPEG, PNG o BMP";
      }
      return true;
    };

    Vue.prototype.$rulesAlfaMax40 = function (v) {
      const pattern = /^[A-Za-z\s]{2,40}$/;
      if (!pattern.test(v))
        return "Debe tener entre 2 y 40 caracteres";

      return true;
    };

    Vue.prototype.$rulesMax50 = function (v) {
      if (typeof v !== "undefined") {
        return String(v).length <= 50 || "Max. 50 caracteres";
      }
      return true;
    };

    Vue.prototype.$rulesMax500 = function (v) {
      if (typeof v !== "undefined") {
        return v.length <= 500 || "Max. 500 caracteres";
      }
      return true;
    };

    Vue.prototype.$rulesMax3 = function (v) {
      if (typeof v !== "undefined") {
        return String(v).length <= 3 || "Max. 3 caracteres";
      }
      return true;
    };

    Vue.prototype.$rulesMax6 = function (v) {
      if (typeof v !== "undefined") {
        return String(v).length <= 6 || "Max. 6 caracteres";
      }
      return true;
    };

    Vue.prototype.$rulesLegajo = function (value) {
      if (typeof value !== "undefined") {
        if (String(value).length < 20) {
          return true;
        } else {
          return "Este campo no puede contener mas de 20 caracteres.";
        }
      } else {
        return "Este campo no puede contener mas de 20 caracteres.";
      }
    };

    Vue.prototype.$rulesFechaMayorAHoy = function (value) {
      try {
        const fechaIngresada = new Date(value);
        const fechaActual = new Date();

        if (fechaIngresada > fechaActual) {
          return true;
        } else {
          return "La fecha debe ser posterior a la fecha actual.";
        }
      } catch (error) {
        console.log("rulesFechaMayorAHoy: " + error);
        return false;
      }
    };

    Vue.prototype.$rulesFechaMenorAHoy = function (date) {
      try {
        if (typeof date !== "undefined") {
          if (date.length == 10) {
            let fechaIngresada = Vue.prototype.moment(date, "DD-MM-YYYY");
            if (fechaIngresada.isValid()) {
              let now = new Date();
              if (fechaIngresada.diff(now) > 0) {
                return "La fecha no puede ser mayor a la fecha actual";
              } else {
                return true;
              }
            } else {
              return "Ingrese una fecha válida";
            }
          } else {
            return "Ingrese una fecha válida";
          }
        }
        return true;
      } catch (error) {
        console.log("rulesFechaMenorAHoy: " + error);
        return false;
      }
    };

    Vue.prototype.$rulesFechaValida = function (date) {
      try {
        if (typeof date !== "undefined") {
          if (date.length == 10) {
            let timestamp = Vue.prototype.moment(date, "DD-MM-YYYY");
            if (timestamp.isValid()) {
              return true;
            } else {
              return "Ingrese una fecha válida";
            }
          } else {
            return "Ingrese una fecha válida";
          }
        }
        return true;
      } catch (error) {
        console.log("rulesFechaValida: " + error);
      }
      return false;
    };

    Vue.prototype.$rulesAnioMin = function (v) {
      if (v <= 1885) return "El año debe ser mayor a 1886";

      return true;
    };
    Vue.prototype.$rulesAnioMax = function (v) {
      if (v > parseInt(new Date().getFullYear()))
        return "El año no puede ser mayor a " + new Date().getFullYear();

      return true;
    };
  },
};
