export default {

  setTransacciones(state, transacciones) {
    state.transacciones = transacciones;
  },

  setTransaccionDetalle(state, transaccionDetalle) {

    console.log('Mutación setTransaccionDetalle:', transaccionDetalle);
    state.transaccionDetalle = transaccionDetalle;
  },

  addTransacciones(state, nuevasTransacciones) {
    state.transacciones = [...state.transacciones, ...nuevasTransacciones];
  }

  /*addTransaccion(state, transaccion) {
    state.transacciones.unshift(transaccion);
  },*/

};
