export default {
    install(Vue) {

        // Formatea un numero a moneda 
        Vue.prototype.$formatCurrency = function (value) {
            if (value === 0) return '$ 0.00';
            return '$ ' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        };

        Vue.prototype.$formatCurrencyValue = function (value) {
            if (value === 0) return '0,00';
            const [integerPart, decimalPart] = value.toFixed(2).split('.');
            const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return `${formattedInteger},${decimalPart}`;
          };
    }
}