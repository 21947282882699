import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import AxiosApi from "./plugins/axiosApi"

Vue.use(Vuex);

import carrito from "./modules/carrito";
//import anmac from "./modules/anmac";
//import aduana from "./modules/aduana";
//import saldoCC from "./modules/saldoCC";
import transacciones from "./modules/transacciones";

export default new Vuex.Store({
  modules: {
    carrito,
    //anmac,
    //aduana,
    //saldoCC,
    transacciones,
  },

  plugins: [createPersistedState()],
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    /*barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',*/
    barImage: "",
    drawer: null,
    keyDrawer:0,
    authenticated: false,
    drawerLoading: true,
    user: {
      id:null,
      username:null,
      name:null,
      roles: Array,
      permisos: Array,
      entidades: Array,
      notificaciones: [],
      sapProperties:{},
      /*carrito: {
        data: [], // Inicializado como un arreglo vacío
        cantItemTotales: 0,
        cantItem: 0,
        total: 0
      }*/
    },
    cartEditPath: null,
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_BAR_COLOR(state, payload) {
      state.barColor = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    async SET_USER (state, payload){
      try{
        let userId = payload.tokenParsed.sub;
        state.user.id = userId;
        state.user.username = payload.tokenParsed.preferred_username;
        state.user.name = payload.tokenParsed.given_name +
        " " +
        payload.tokenParsed.family_name;
        const client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
        if(payload.tokenParsed.groups){
          const roleUser = payload.tokenParsed.groups.filter(item => item.substring(item.indexOf(' - ')+3) == client)
          .map(item => item.substring(1, item.indexOf(' - ')));
          state.user.roles = roleUser;
        }
        else{
          state.user.roles = [];
        }
        state.user.permisos = [];
        //console.log("state.user.permisos");
        //console.log(payload);
        if(payload.resourceAccess){
          for (const key in payload.resourceAccess) {
              if(key == client){
                state.user.permisos = payload.resourceAccess[key].roles;
                break;
              }
          }
        }
        else{
          state.user.permisos = [];
        }
        //busco la/s entidad/es
        if(state.user.entidades.length < 1)
        {
            let axiosApi = new AxiosApi(payload.token)
            axiosApi.getByCriteria('usuarios_entidades', userId+'/'+client)
            .then((r) => {
              if(r.data.data){

                let arrayEntidades = r.data.data;
                let entidades = [];
                for (const k in arrayEntidades) {

                  entidades.push(arrayEntidades[k].entidad_id);

                }
                state.user.entidades = entidades;
              }
              else{
                state.user.entidades = []
              }

            })
            .catch(function (error) {
              console.log(error);
            });
        }
        //datos de usuarios
        let axiosApiUser = new AxiosApi(payload.token)
        axiosApiUser.getByCriteria('usuarios', userId)
            .then((r) => {
              if(r.data.data){
                 state.user.userInfo = r.data.data;
              }

            })
            .catch(function (error) {
              console.log(error);
            });

        //state.user.notificaciones = [];
        let axiosApi = new AxiosApi(payload.token)
            axiosApi.getByCriteria('notificacionesByUser', userId)
            .then((r) => {
              if(r.data.data){
                state.user.notificaciones = r.data.data;
              }
              else{
                state.user.notificaciones = []
              }
            })
            .catch(function (error) {
              console.log(error);
            });

            return 1;
      }
      catch(error){
        return error;
      }


    },
    async SET_DATA_SAP(state, payload){
      try{


      if(typeof state.user.sapProperties.CardCode == 'undefined'  )
        {

            state.drawerLoading = true;

            const respSap = async (dataUser) => {
              let username = dataUser.tokenParsed.preferred_username;

              let params = username.toUpperCase() + "/TST_MJV20";

              let axiosApiUser = new AxiosApi(payload.token)
              const response = await axiosApiUser.getByCriteria(
                'bussinessPartnerClient',
                params
              );

              return response.data.data;
            };
            let resp = await respSap(payload);
            if (resp) {
              state.user.sapProperties = resp;

              state.keyDrawer = + 1;


            } else {
              state.user.sapProperties = {};
              state.keyDrawer = + 1;


            }

            state.drawerLoading = false;
        }
        else{
             state.drawerLoading = false;

        }
      }
      catch (error) {
        console.log(error);
      }

    },
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_CART_EDIT_PATH(state, value) {
      state.cartEditPath = value;
    },
    RESET_USER (state){
      state.user.id = null;
      state.user.username = null,
      state.user.name = null;
      state.user.roles =[];
      state.user.permisos = [];
      state.user.entidades = [];
      state.user.notificaciones = [];
      state.user.userInfo = [];
      state.drawerLoading= true;
      state.user.sapProperties={};
      state.carrito = {
        data: [], // Inicializado como un arreglo vacío
        cantItemTotales: 0,
        cantItem: 0,
        subtotal: 0,
        gastos:0,
        total: 0
      }


    },
    ADD_NOTIFICATION(state, notification) {
      state.user.notificaciones.push(notification);
    },
/*
    remove(state) {
      state.user.carrito= {
        data: [], // Inicializado como un arreglo vacío
        cantItemTotales: 0,
        cantItem: 0,
        subtotal: 0,
        gastos:0,
        total: 0
      }
    },
    addCantidad(state, item) {

      let index = -1;

      if (Array.isArray(state.user.carrito.data) && state.user.carrito.data.length > 0) {
        index = state.user.carrito.data.findIndex(p => p.ItemCode === item.ItemCode);
      }

      if (index !== -1) {
        state.user.carrito.data[index].error = "";
        // Buscar el índice del producto en el array 'desserts' por 'ItemCode'

        // Actualizar las unidades del producto
        state.user.carrito.data[index].unidades =
        state.user.carrito.data[index].unidades == "" ||
          isNaN(state.user.carrito.data[index].unidades)
            ? 0
            : state.user.carrito.data[index].unidades;

            state.user.carrito.data[index].unidades += Number(
              state.user.carrito.data[index].SalesQtyPerPackUnit
            );
        //state.user.carrito.data[index].unidades =item.unidades



        //state.user.carrito.total += (Number(
        //  state.user.carrito.data[index].SalesQtyPerPackUnit
        //)* item.priceList.Price);

        // Forzar la reactividad reemplazando el objeto
         //Vue\.set(state.user.carrito, index, { ...state.user.carrito});

        //console.log(state.user.carrito); // Muestra la cantidad actualizada

      }
      this.commit('calcularTotales',state);
    },
    removeCantidad(state, item) {
      // Buscar el índice del producto en el array 'desserts' por 'ItemCode'
      let index = state.user.carrito.data.findIndex((p) => p.ItemCode === item.ItemCode);

      if (index !== -1) {
        state.user.carrito.data[index].error = "";
        // Actualizar las unidades del producto
        if (
          state.user.carrito.data[index].unidades -
            Number(state.user.carrito.data[index].SalesQtyPerPackUnit) >=
          0
        ) {

           state.user.carrito.data[index].unidades -= Number(state.user.carrito.data[index].SalesQtyPerPackUnit);



        } else {
          state.user.carrito.data[index].unidades = 0;
        }

        // Forzar la reactividad reemplazando el objeto
        //Vue\.set(state.user.carrito, index, { ...state.user.carrito });
      }
       this.commit('calcularTotales',state);
    },
    removeItem(state, item) {
      let index = -1;

      if (Array.isArray(state.user.carrito.data) && state.user.carrito.data.length > 0) {
        index = state.user.carrito.data.findIndex(p => p.ItemCode === item.ItemCode);
      }

      if (index !== -1) {
        let arrayAuxCarrito = state.user.carrito.data;
        arrayAuxCarrito.splice(index, 1);
        state.user.carrito.data = arrayAuxCarrito;
      }
      this.commit('calcularTotales',state);

    //Vue\.set(state.user.carrito, index, { ...state.user.carrito });

    },

    async addOrUpdateItem(state, item) {

      let index = -1;

      if (Array.isArray(state.user.carrito.data) && state.user.carrito.data.length > 0) {
        index = state.user.carrito.data.findIndex(p => p.ItemCode === item.ItemCode);
      }

      if (index !== -1) {
        console.log('linea 349');
        // Modifica el item si ya existe en el carrito
        //let unidadesPrevias = (state.user.carrito.data[index].unidades);


        // Modifica el item si ya existe en el carrito
        state.user.carrito.data[index].unidades = item.unidades;


      } else {
        //si esta el carrito vacio
        if (!Array.isArray(state.user.carrito.data)){
          console.log('linea 374');
          state.user.carrito.data = [item];

        }
        else
        {
          console.log('linea 385');
          state.user.carrito.data.push(item);
        }
        // Agrega el nuevo item al carrito

      }
      this.commit('calcularTotales',state);

    },

    addOrUpdateItemManual(state, item) {

      let index = -1;
      let unidadesPrevias;

      if (Array.isArray(state.user.carrito.data) && state.user.carrito.data.length > 0) {
        index = state.user.carrito.data.findIndex(p => p.ItemCode === item.ItemCode);
      }

      if (index !== -1) {

        state.user.carrito.data[index].unidades = item.unidades;


      } else {
        //si esta el carrito vacio
        if (!Array.isArray(state.user.carrito.data)){
          state.user.carrito.data = [item];
        }
        else
        {
          state.user.carrito.data.push(item);
        }
      }
      this.commit('calcularTotales',state);
    },

    async CARRITO_VALIDATE_DIVISOR(state, item) {

      let valor = item.unidades;
          let divisor = Number(item.SalesQtyPerPackUnit);
          let index = state.user.carrito.data.findIndex((p) => p.ItemCode === item.ItemCode);
          if (typeof valor === "undefined") {
            return true;
          }
          if (index !== -1) {
            if (!divisor || divisor === 0) {
              // Si el divisor es 0 o nulo, muestra el error específico en ese campo
              state.user.carrito.data[index].error = "El divisor no puede ser 0 o vacío";

              return false;
            } else {
              if (valor % divisor !== 0) {
                // Si el valor no es divisible por el divisor, muestra el mensaje de error
                //state.user.carrito.data[index].error = `El número ${valor} no es divisible por ${divisor}`;
                state.user.carrito.data[index].error  =   "Las unidades deben ser múltiplos de la cantidad mínima (" +divisor+")";
                state.user.carrito.data[index].disabled = true;


                return false;
              } else {
                // Si todo es correcto, borra el error
                state.user.carrito.data[index].error = "";
                state.user.carrito.data[index].disabled = false;

                return true;
              }
            }
          }
    },

    calcularTotales(state){
      state.user.carrito.cantItemTotales = 0;
        state.user.carrito.subtotal = 0;
        state.user.carrito.total = 0;

        state.user.carrito.data.forEach((item, index) => {
          state.user.carrito.cantItemTotales += item.unidades;
          state.user.carrito.subtotal += (item.unidades * item.priceList.Price);
          state.user.carrito.gastos = Math.round(state.user.carrito.subtotal*0.05,1);


        });
         state.user.carrito.total += state.user.carrito.subtotal + state.user.carrito.gastos;

        state.user.carrito.cantItem = state.user.carrito.data.length;
    }*/
  },



  actions: {

    /*validateDivisor({commit}){
      let valor = item.unidades;
      let divisor = Number(item.SalesQtyPerPackUnit);
      let index = state.carrito.data.findIndex((p) => p.ItemCode === item.ItemCode);
      if (typeof valor === "undefined") {
        return true;
      }
      if (index !== -1) {
        if (!divisor || divisor === 0) {
          // Si el divisor es 0 o nulo, muestra el error específico en ese campo
          state.carrito.data[index].error = "El divisor no puede ser 0 o vacío";

          return false;
        } else {
          if (valor % divisor !== 0) {
            // Si el valor no es divisible por el divisor, muestra el mensaje de error
            //state.carrito.data[index].error = `El número ${valor} no es divisible por ${divisor}`;
            state.carrito.data[index].error  =   "Las unidades deben ser múltiplos de la cantidad mínima (" +divisor+")";

            state.carrito.data[index].disabled = true;


            return false;
          } else {
            // Si todo es correcto, borra el error
            state.carrito.data[index].error = "";
            state.carrito.data[index].disabled = false;

            return true;
          }
        }
      }
    },*/

    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification);
    },
    logout({ commit }) {
      commit('RESET_USER',{})
      commit('SET_AUTHENTICATED',false)
      commit('carrito/remove', {})

    },
    async login({ commit }) {
      let client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
      if(Vue.prototype.$keycloak)
      {
        let userId = Vue.prototype.$keycloak.tokenParsed.sub;

          await commit('SET_DATA_SAP',Vue.prototype.$keycloak);
          await commit('SET_USER',Vue.prototype.$keycloak);
          commit('SET_AUTHENTICATED',true)


      }
    },



  },
  getters: {
    isLogged: (state) => !!state.user,
    authenticated(state) {
      return state.authenticated;
    },
  },
});
