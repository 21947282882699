export default {
  agregarOActualizarItem({ commit }, item) {
    commit('addOrUpdateItem', item);
  },
  eliminarItem({ commit }, item) {
    commit('removeItem', item);
  },
  vaciarCarrito({ commit }) {
    commit('remove');
  },

  async validateDivisor({ commit }, item) {
    // Puedes tener lógica asíncrona aquí si la necesitas
    // Luego llamas a la mutación
    let result = commit('validateDivisor', item);
    return result;
  }
}
