<template>
  <v-list-group
    :group="group"

    :sub-group="subGroup"
    append-icon="mdi-menu-down"
    :color="
      barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'
        ? 'white'
        : 'grey darken-1'
    "

    class="my-2 py-0"
  >
    <template v-slot:activator >
    <!--
      <v-list-item-avatar

        class="align-self-center py-0 my-0"
        color="white"
        contain
      >
        <v-img
          src="/img/comprar.png"
        />
      </v-list-item-avatar>
-->
      <v-list-item-content >
        <v-list-item-title class="tituloGrupo ml-1" v-text="item.title" style="color:#000"/>
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in children">
      <base-item-sub-group
        v-if="child.children"
        :key="`sub-group-${i}`"
        :item="child"
        class="py-0 my-0"

      />

      <base-item v-else :key="`item-${i}`" :item="child" text />
    </template>
  </v-list-group>
</template>
<style>
.v-list--nav{
  margin-left: 2px !important;
  margin-right: 0px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.tituloGrupo{

  font-weight: 600 !important;
}
.v-list--nav .v-list-item:not(:last-child):not(:only-child), .v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
    margin-bottom: 1px !important;
}
/*
.v-list-group__header .v-list-item .v-list-item--active .v-list-item--link .theme--dark{
  color: #000 !important;
  background-color:#fff !important;
  opacity:1;
}*/
.v-list-item__title{
  font-weight: 400 !important;

}
.v-list .v-list-item {
    color: #000 ;


}



.v-list-item--active    {
      color:var(--bs-primary-2)!important;
      background-color: var(--bs-secondary-2)  !important;
  }
.v-list-item:hover {
    color: var(--bs-primary-2) !important;
    background-color: var(--bs-secondary-2) !important;
}

.v-list-group__header{
  background-color: #fff !important;
  color: #000 !important;
}

#core-navigation-drawer .v-list-group__header.v-list-item--active:before {


    background-color: #fff !important;
    color: #000 !important;
}
</style>
<script>
// Utilities
import kebabCase from "lodash/kebabCase";
import { mapState } from "vuex";

export default {
  name: "ItemGroup",

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: [],
      }),
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(["barColor"]),
    children() {
      return this.item.children.map((item) => ({
        ...item,
        to: !item.to ? undefined : `${this.item.group}/${item.to}`,
      }));
    },
    computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    group() {
      return this.genGroup(this.item.children);
    },
  },

  methods: {
    genGroup(children) {
      return children
        .filter((item) => item.to)
        .map((item) => {
          const parent = item.group || this.item.group;
          let group = `${parent}/${kebabCase(item.to)}`;

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`;
          }

          return group;
        })
        .join("|");
    },
  },
};
</script>

<style>
.v-list-group__activator p {
  margin-bottom: 0;
}
</style>
